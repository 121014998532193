const POST_SUBMIT_ACTION = {
  SHOW_SUCCESS_MESSAGE: 'show_success_message',
  REDIRECT_TO_URL: 'redirect_to_url',
  HIDE_THE_FORM: 'hide_the_form',
};

export class FormBuilder {
  scriptUrl = `${window._env_.BASE_URL}dist/formBuilder-bundle.js`;
  stylesUrl = `${window._env_.BASE_URL}dist/formBuilder.css`;
  apiUrl = window._env_.API_URL;
  formSelector = '[data-team-id][data-form-id]';
  forms = null;
  initialized = false;
  scriptFetched = false;
  isFingerPrintScriptIncluded = false;

  init() {
    if (!this.initialized) {
      this.initialized = true;
      this.forms = document.querySelectorAll(this.formSelector);
      if (this.forms.length > 0) {
        this._includeFingerprint();
        this._fetchReactFormScript();
      }
    }
  }

  _includeFingerprint() {
    window.io_bb_callback = (bb, complete) => {
      window.bbDeviceFingerprint = bb;
    };
    const script = document.createElement('script');
    script.src = 'https://mpsnare.iesnare.com/snare.js';
    script.async = true;
    this.isFingerPrintScriptIncluded = true;
    document.body.appendChild(script);
  }

  setLocale(locale) {}

  updateField(fieldName, options) {}

  afterSubmitAction({ layout, teamId, formId, ...data }) {
    const url = layout?.postSubmit?.url;
    const action = layout?.postSubmit?.action;

    if (!action) {
      return;
    }
    switch (action) {
      case POST_SUBMIT_ACTION.REDIRECT_TO_URL:
        if (url) {
          location.href = url;
        } else {
          console.warn('Missing url in post submit action');
        }
        break;
      case POST_SUBMIT_ACTION.HIDE_THE_FORM: {
        const div = document.querySelector(`[data-form-id="${formId}"]`);
        if (div?.tagName === 'DIALOG') {
          div.close();
        } else {
          div.style.display = 'none';
        }

        break;
      }
    }
  }

  _fetchReactFormScript() {
    const script = document.createElement('script');
    script.src = this.scriptUrl;
    script.async = true;
    script.onload = () => {
      this.scriptFetched = true;
      this.renderForms();
    };
    document.body.appendChild(script);
  }

  _getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
      return parts
        .pop()
        .split(';')
        .shift();
  };

  findConfigByElementId(elementId) {
    return (window.tintForms && window.tintForms[elementId]) || {};
  }

  renderForms() {
    document.querySelectorAll(this.formSelector).forEach(div => {
      let {
        teamId: configTeamId = undefined,
        formId: configFormId = undefined,
        locale: configLocale = undefined,
        noTrack: configNoTrack = undefined,
        values: configFormValues = undefined,
        events: configEvents = undefined,
      } = this.findConfigByElementId(div.id);

      let teamId = div.getAttribute('data-team-id') || configTeamId;
      let formId = div.getAttribute('data-form-id') || configFormId;
      let locale = div.getAttribute('data-form-locale') || configLocale || document.documentElement.lang;
      let noTrack = div.getAttribute('data-notrack') != undefined || configNoTrack; // boolean attribute, if it exists, it's true
      let formValues = div.getAttribute('data-form-values') || configFormValues;
      let onAfterLoad = configEvents?.onAfterLoad ? configEvents.onAfterLoad : null;
      let onSubmitUser = configEvents?.onAfterSubmit ? configEvents.onAfterSubmit : null;
      let onBeforeSubmit = configEvents?.onBeforeSubmit ? configEvents.onBeforeSubmit : null;
      let onSubmitFinal = function(data) {
        if (onSubmitUser) {
          if (!onSubmitUser?.(data)) return;
        }
        this.afterSubmitAction?.(data);
      };
      window.Tint?.tintFormBuilderRender?.({
        element: div,
        stylesUrl: this.stylesUrl,
        teamId,
        formId,
        locale,
        noTrack,
        formValues,
        apiUrl: this.apiUrl,
        tintAnonymousUid: this._getCookie('tint-anonymous-uid'),
        onAfterSubmit: onSubmitFinal.bind(this),
        onAfterLoad: onAfterLoad?.bind(this),
        onBeforeSubmit: onBeforeSubmit?.bind(this),
      });
    });
  }
}
